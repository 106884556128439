export default {
    components: {
        TheLocaleSwitcher: {
            locales: {
                de: 'deutsch',
                en: 'english',
            },
            toast: 'Sprache geändert',
        },
    },
    pages: {
        DashboardPage: {
            title: 'Dashboard',
            statusTeaser: {
                title: 'Statuspage',
                content: 'Informationen zu zukünftigen und laufenden Wartungen.',
                action: 'Seite öffnen',
            },
            helpTeaser: {
                title: 'Knowledge Base',
                content: 'Weiterführende Informationen und antworten auf häufig gestellte Fragen.',
                action: 'Seite öffnen',
            },
            smokePingTeaser: {
                title: 'SmokePing',
                content: 'Prüfen Sie die Latenz unseres Netzwerks und messen Sie seine Leistung.',
                action: 'Seite öffnen',
            },
            contactTeaser: {
                title: 'Kontakte',
                phone: 'Telefon',
                mail: 'Mail',
                emergency: 'Notfall',
            },
            serviceTeaser: {
                title: 'Servicezeiten',
                mondayToThursday: 'Mo. bis Do.',
                mondayToThursdayOpeningHours: '8:00 bis 17:00 Uhr',
                friday: 'Fr.',
                fridayOpeningHours: '8:00 bis 15:30 Uhr',
                info: 'Alle Zeiten in deutscher Lokalzeit ({timeZone} [{utcDifference}])',
            },
            changelogSection: {
                title: 'Letzte @:pages.ChangelogIndexPage.title',
            },
            newsPostsSection: {
                title: 'Letzte @:pages.NewsPostIndexPage.title',
            },
            ticketSection: {
                title: 'Offene Tickets',
            },
            helpfulLinksSection: {
                title: 'Hilfreiche Links',
            },
        },
        CustomerPage: {
            title: 'Kundendaten',
            breadcrumb: '@:pages.CustomerPage.title',
            contactSection: {
                inactiveSwitch: 'Deaktivierte @:resources.contact.name',
            },
        },
        OrderIndexPage: {
            title: 'Aufträge',
            breadcrumb: '@:pages.OrderIndexPage.title',
        },
        OrderPage: {
            title: 'Auftrag: %{number}',
            breadcrumb: '%{number}',
            offerSection: {
                title: 'Ihr persönliches Angebot',
                body: 'Mit einem Klick auf „Annehmen“ bestätigen Sie das Angebot und schließen den Auftrag verbindlich ab. Wählen Sie „Ablehnen“, können Sie uns einen Grund oder Ihre Änderungswünsche mitteilen, damit wir das Angebot entsprechend anpassen können.',
                actions: {
                    approve: 'Annehmen ...',
                    reject: 'Ablehnen ...',
                },
                approveDialog: {
                    title: 'Angebot annehmen?',
                    body: 'Mit der Annahme dieses Angebots lösen Sie eine verbindliche und kostenpflichtige Bestellung aus. Bitte überprüfen Sie vor Abschluss noch einmal alle Details.',
                    action: 'Kostenpflichtig annehmen',
                    successMessage: 'Angebot wurde angenommen.',
                },
                rejectDialog: {
                    title: 'Angebot ablehnen?',
                    body: 'Bitte teilen Sie uns mit, warum Sie das Angebot ablehnen oder welche Änderungen Sie wünschen. So können wir noch besser auf Ihre Anforderungen eingehen.',
                    fieldLabel: 'Grund für die Ablehnung',
                    fieldPlaceholder: 'Ich lehne das Agebot ab, weil ...',
                    action: 'Entgültig ablehnen',
                    successMessage: 'Angebot wurde abgelehnt.',
                },
            },
            type: {
                s3: {
                    managementInterfaceSection: {
                        title: 'Verwaltungsoberfläche',
                        groupName: 'Gruppenname: Customers',
                        incompleteSetUp:
                            'Der Zugang zur Verwaltungsoberfläche ist noch nicht vollständig eingerichtet. Bitte wenden Sie sich an unseren Support.',
                    },
                    endpointSection: {
                        title: 'S3-Endpunkte (via HTTP und HTTPS)',
                        hint: 'Die S3-Zugangsdaten sind in der Verwaltungsoberfläche (Der Link unter Ihrer Benutzer-ID) unter Ihrem Nutzernamen zu finden ("Security Credentials"). Buckets und Access Keys können Sie dann über die Oberfläche verwalten.',
                    },
                },
                cloud: {
                    managementInterfaceSection: {
                        title: 'Verwaltungsoberfläche',
                        hint: 'Über den Button gelangen Sie zur Administrationsoberfläche und können dort Ihre Computing Instanzen verwalten. Verwenden Sie dazu die festgelegten Zugangsdaten oder fordern Sie einmalig Ihr Passwort an.',
                    },
                },
            },
        },
        IpUsageIndexPage: {
            title: 'IPAM',
            breadcrumb: '@:pages.IpUsageIndexPage.title',
        },
        IpUsageNetworkPage: {
            title: 'IP-Netz',
            breadcrumb: 'IP-Netz',
        },
        OrderWanPage: {
            title: 'WAN-Traffic',
            breadcrumb: '@:pages.OrderWanPage.title',
        },
        OrderLanPage: {
            title: 'LAN-Traffic',
            breadcrumb: '@:pages.OrderLanPage.title',
        },
        OrderPowerPage: {
            title: 'Stromverbrauch',
            breadcrumb: '@:pages.OrderPowerPage.title',
        },
        DeviceUsageIndexPage: {
            title: 'Server',
            breadcrumb: '@:pages.DeviceUsageIndexPage.title',
        },
        RentUsageIndexPage: {
            title: 'Mietgeräte',
            breadcrumb: '@:pages.RentUsageIndexPage.title',
        },
        ColocationUsageIndexPage: {
            title: 'Colocation',
            breadcrumb: '@:pages.ColocationUsageIndexPage.title',
        },
        InvoiceIndexPage: {
            title: 'Rechnungen',
            breadcrumb: '@:pages.InvoiceIndexPage.title',
        },
        TicketIndexPage: {
            title: 'Tickets',
            breadcrumb: '@:pages.TicketIndexPage.title',
        },
        TicketPage: {
            breadcrumb: '%{id}',
            ticketCreated: 'hat @:resources.ticket.name angelegt',
            commented: 'hat kommentiert',
            avatarAlt: 'Profilbild von %{name}',
            ticketSolved: 'Ticket wurde gelöst.',
        },
        SettingsAccountPage: {
            title: 'Einstellungen',
            breadcrumb: '@:pages.SettingsAccountPage.title',
            twoFactorAuthenticationDialog: {
                title: 'Zwei-Faktor-Authentifizierung aktivieren',
                step1Label: 'QR-Code scannen',
                step1Description:
                    'Verwenden Sie zum Scannen eine Authentifizierungs-App wie <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://authy.com/"> Authy </a>, <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=de&gl=US"> Google Authenticator </a> oder eine Browser-Erweiterung wie die von <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://1password.com/downloads/browser-extension/">1Password</a>.',
                confirmationCodeField: 'Bestätigungscode',
                step2Label: 'Bestätigungscode eingeben',
                step2Description:
                    'Geben Sie den Code aus der Authentifizierungs-App zur Bestätigung ein.',
                step3Label: 'Recovery Codes speichern',
                step3Description:
                    'Speichern Sie Ihre Recovery-Codes an einem sicheren Ort, damit Sie bei Verlust Ihrer Authentifizierungs-App wieder Zugriff auf Ihren Account bekommen können.',
            },
        },
        ChangelogIndexPage: {
            title: 'Changelogs',
            breadcrumb: '@:pages.ChangelogIndexPage.title',
        },
        NewsPostIndexPage: {
            title: 'News',
            breadcrumb: '@:pages.NewsPostIndexPage.title',
        },
    },
    resources: {
        address: {
            name: 'Adresse | Adressen',
            attributes: {
                street: 'Straße',
                number: 'Hausnummer',
                zip: 'PLZ',
                city: 'Stadt',
                additional: 'Zusatz',
                country: 'Land',
            },
            emptyState: {
                title: '@:resources.address.name anlegen',
            },
        },
        country: {
            name: 'Land | Länder',
            attributes: {
                code: 'Ländercode',
                name: 'Name',
            },
        },
        bankAccount: {
            name: 'Bankverbindung | Bankverbindungen',
            emptyState: {
                title: '@:resources.bankAccount.name anlegen',
                body: 'Wenden Sie sich an unseren Support und legen Sie ihre @:resources.bankAccount.name an',
                mailSubject: '[23M.com] Bankverbindung anlegen ({customerNumber})',
                mailBody:
                    'Hallo 23M-Support-Team,%0D%0A%0D%0A ich möchte gerne folgende Bankverbindung anlegen...',
            },
        },
        contact: {
            name: 'Kontaktperson | Kontaktpersonen',
            attributes: {
                is_authorized: 'Weisungsbefugt',
                has_account: '@:resources.user.nameAlternative vorhanden',
            },
            actions: {
                add: '@:resources.contact.name anlegen',
            },
            emptyState: {
                title: '@:resources.contact.name anlegen',
            },
            messages: {
                canNotDeleteYourself: 'Sie können sich selbst nicht löschen.',
                deactivateConfirmation:
                    'Möchten Sie diese @:resources.contact.name wirklich deaktivieren?',
            },
        },
        customer: {
            name: 'Kunde',
            attributes: {
                number: 'Kundennummer',
                name: 'Name',
                vat_id: 'USt.-ID',
                payment_type: 'Zahlungsart',
                payment_due_days: 'Zahlungsziel',
                receipt_mail_address: 'Rechnungs-Mail',
            },
            enums: {
                payment_type: {
                    bank_transfer: 'Überweisung',
                    direct_debit: 'Bankeinzug',
                    paypal: 'Paypal',
                },
            },
            receiptMailAddressEmptyState: {
                title: '@:resources.customer.attributes.receipt_mail_address anlegen',
            },
        },
        user: {
            name: 'Benutzer',
            nameAlternative: 'Login',
            attributes: {
                name: 'Name',
                password: 'Passwort',
                recovery_codes: 'Recovery Codes',
            },
            actions: {
                createAccount: {
                    label: '@:resources.user.nameAlternative anlegen',
                    hint: 'Es wird ein Login anhand der Kontakdaten angelegt und eine Einladungs-Mail verschickt.',
                },
                confirmPassword: 'Passwort bestätigen',
                activateTwoFactorAuthentication: {
                    successMessage: 'Zwei-Faktor-Authentifizierung aktiviert',
                },
                deactivateTwoFactorAuthentication: {
                    successMessage: 'Zwei-Faktor-Authentifizierung deaktiviert',
                },
            },
        },
        order: {
            name: 'Auftrag',
            attributes: {
                number: 'Auftragsnummer',
                status: 'Status',
                type: 'Auftragsart',
                completed_at: 'Komplett seit',
                approved_at: 'Bearbeitet seit',
                planned_start_at: 'Geplant zum',
                start_at: 'Startdatum',
                end_at: 'Gekündigt zum',
                setup_price: 'Einmalige Kosten',
                monthly_price: 'Monatliche Kosten',
                price: 'Preis',
                cancelation_period: 'Kündigungsfrist',
                extension_period: 'Verlängerung',
                min_order_duration: 'Mindestlaufzeit',
            },
            enums: {
                status: {
                    draft: 'Draft',
                    completed: 'Angebot',
                    approved: 'Genehmigt',
                    due: 'Fällig',
                    active: 'Aktiv',
                    canceled: 'Gekündigt',
                    terminated: 'Gekündigt',
                    rejected: 'Abgelehnt',
                },
                decoratedStatus: {
                    startAt: 'Geplanter Start: {date}',
                    startsShortly: 'Beginnt bald',
                    endAt: 'Gekündigt zum {date}',
                },
                type: {
                    dedicated: 'Dedicated Server',
                    rent: 'Miete',
                    colocation: 'Colocation',
                    service: 'Service',
                    cloudserver: 'Cloud Server',
                    cloud: 'Cloud',
                    ip: 'Ip',
                    s3: 'S3 Object Storage',
                    domain_robot: 'Domain',
                    network: 'Network',
                },
            },
            ticket: {
                subject: '[@:resources.order.name {orderNumber}] ',
            },
        },
        orderItem: {
            name: 'Auftragsposition | Auftragspositionen',
            attributes: {
                category: 'Kategorie',
                type: 'Typ',
                description: 'Beschreibung',
            },
            enums: {
                category: {
                    device: 'Hardware',
                    ram: 'RAM',
                    storage: 'Storage',
                    network: 'Netzwerk',
                    service: 'Service | Services',
                    ip: 'IP-Adresse | IP-Adressen',
                    s3: 'S3 Object Storage',
                    power: 'Strom',
                    unknown: 'Unknown',
                    vpn: 'VPN',
                },
            },
            actions: {
                ipmiRequest: 'IPMI anzeigen',
                showLanTrafficGraph: 'LAN-Traffic',
            },
            errors: {
                ipmiRequest:
                    'Bei der IPMI-Abfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
            },
        },
        ipUsageAddress: {
            name: '',
            attributes: {
                ip: 'IP-Adresse | IP-Adressen',
                order_number: '@:resources.order.name',
                dns_name: 'rDNS Name',
                customer_reference: 'Kundenreferenz',
            },
        },
        ipUsageNetwork: {
            name: '',
            attributes: {
                ip: 'IP-Netz | IP-Netze',
                order_number: '@:resources.order.name',
                network_ips: 'rDNS-Einträge',
                has_dns_management: '@:resources.ipUsageAddress.attributes.has_dns_management',
                customer_reference: '@:resources.ipUsageAddress.attributes.customer_reference',
            },
        },
        networkIp: {
            name: '',
            attributes: {
                ip: 'IP-Adresse | IP-Adressen',
                mask: 'Subnetzmaske',
                dns_name: 'rDNS Name',
            },
        },
        deviceUsage: {
            name: 'Server | Server',
            attributes: {
                device_unit_name: 'Name',
                device_unit_old_name: 'Legacy-Name',
                device_name: 'Typ',
                cpu: 'CPU',
                order_number: 'Auftrag',
                customer_reference: '@:resources.ipUsageAddress.attributes.customer_reference',
                device_unit_component_unit_descriptions: 'Komponenten',
                device_unit_netbox_item_rack_name: 'Rack',
                device_unit_netbox_item_rack_room: 'Raum',
            },
            actions: {
                createCustomerReference:
                    '@:resources.deviceUsage.attributes.customer_reference anlegen',
                editCustomerReference:
                    '@:resources.deviceUsage.attributes.customer_reference bearbeiten',
            },
            ticket: {
                subject: '[@:resources.deviceUsage.name {name}] ',
            },
        },
        rentUsage: {
            name: 'Rental | Rentals',
            attributes: {
                device_unit_name: '@:resources.deviceUsage.attributes.device_unit_name',
                device_name: '@:resources.deviceUsage.attributes.device_name',
                order_number: '@:resources.deviceUsage.attributes.order_number',
                device_unit_serial: 'Serial',
                device_type: 'Gerätetyp',
            },
            ticket: {
                subject: '[@:resources.rentUsage.name {name}] ',
            },
        },
        colocationUsage: {
            name: '',
            attributes: {
                id: 'ID',
                rack_name: 'Rack',
                rack_room: 'Raum',
                order_number: '@:resources.order.name',
                order_colocation_item_customer_reference:
                    '@:resources.ipUsageAddress.attributes.customer_reference',
                order_colocation_item_colocation_name: 'Produkt',
                order_colocation_item_order_feed_item_current_power_consumption: 'Stromverbrauch',
            },
            actions: {
                createCustomerReference:
                    '@:resources.colocationUsage.attributes.order_colocation_item_customer_reference anlegen',
                editCustomerReference:
                    '@:resources.colocationUsage.attributes.order_colocation_item_customer_reference bearbeiten',
            },
            ticket: {
                subject: '[@:resources.order.name {name}] ',
            },
        },
        orderTrafficItem: {
            name: 'Traffic',
            attributes: {
                pricePerUnit: 'Je weitere {unit} {price}',
            },
            actions: {
                showWanTrafficGraph: 'WAN-Traffic',
            },
        },
        orderS3Item: {
            name: 'S3 Object Storage',
            attributes: {
                username: 'Benutzer-ID',
                pricing: '{pricePerUnit} pro GB / je Monat',
            },
            actions: {
                managementInterface: 'Öffnen',
                editPassword: 'Passwort bearbeiten',
            },
        },
        orderCloudItem: {
            name: 'Cloud',
            attributes: {
                account_name: 'Username',
                password: 'Password',
                domain_name: 'Domain',
            },
            actions: {
                openManagementInterface: 'Open',
                requestPassword: {
                    label: 'Passwort anfordern',
                    confirm: 'Passwort einmalig anfordern',
                    hint: 'Da uns Ihre Sicherheit am Herzen liegt, können Sie Ihr Passwort nur einmal anfordern. Bitte bewahren Sie es sicher auf (z.B. in einem Passwortmanager). Bei Problemen steht Ihnen unser Support gerne zur Verfügung.',
                    success: 'Passwort erfolgreich angefordert',
                    disabled: 'Wurde bereits angefordert',
                },
            },
        },
        orderIpItem: {
            name: 'IP-Adressen',
        },
        orderDomainItem: {
            name: 'Domain Services',
            attributes: {
                domain_username: 'Benutzername',
            },
            actions: {
                openDomainRobot: 'Domain Robot öffnen',
            },
        },
        orderFeedItem: {
            attributes: {
                fuse_protection: 'Absicherung je Feed {fuseProtection} A',
            },
        },
        orderPowerItem: {
            pricing: '{pricePerUnit} pro kWh',
        },
        orderColocationItem: {
            attributes: {
                rack_name: 'Rack',
            },
        },
        orderCloudserverItem: {
            name: 'Cloud Server',
        },
        orderLockerItem: {
            name: 'Schließfach',
            attributes: {
                locker_room: 'Raum',
                locker_name: 'Schrank',
                compartment: 'Fach',
            },
        },
        orderVpnItem: {
            name: '{ count } { type } | { count } { type }s',
        },
        orderCrossConnectItem: {
            name: 'Cross Connect',
            attributes: {
                type: 'Typ',
                usage_interface_name: 'Name',
                netbox_cross_connect_name: 'Kennung',
                netbox_rack_name: 'Rack',
                netbox_rack_room: 'Raum',
                connected_item_order_number: 'Verbundener Auftrag',
            },
        },
        invoice: {
            name: 'Rechnung | Rechnungen',
            attributes: {
                number: 'Rechnungsnummer',
                status: 'Status',
                date: 'Rechnungsdatum',
                due_on: 'Fälligkeit',
                total_net: 'Netto',
                total_gross: 'Brutto',
            },
            enums: {
                status: {
                    open: 'Offen',
                    paid: 'Bezahlt',
                },
            },
        },
        ticket: {
            name: 'Ticket | Tickets',
            attributes: {
                subject: 'Betreff',
                id: 'ID',
                created_at: 'Angelegt am',
                updated_at: 'Letzte Aktivität',
                status: 'Status',
                topic: 'Thema',
                description: 'Beschreibung',
            },
            enums: {
                status: {
                    new: 'Offen',
                    open: 'Offen',
                    pending: 'Wartet auf Ihre Antwort',
                    solved: 'Gelöst',
                    closed: 'Gelöst',
                },
                topic: {
                    sales: 'Produkt- oder Angebotsanfrage',
                    finance: 'Frage zu einer Abrechnung',
                    technic: 'Technischer Support (während der Geschäftszeit)',
                    emergency: 'Technischer Notfall (außerhalb der Geschäftszeit)',
                    miscellaneous: 'Sonstiges',
                },
            },
            actions: {
                selectStatus: 'Beliebig',
                create: '@:resources.ticket.name anlegen',
            },
        },
        ticketComment: {
            name: 'Kommentar | Kommentare',
        },
    },
    messages: {
        info: {
            newOffer: 'Sie haben ein neues Angebot',
            empty: 'Keine Daten gefunden.',
        },
        success: {
            saved: 'Erfolgreich gespeichert',
        },
        thanks: {
            title: 'Danke für Ihr Vertrauen',
            description: 'Wir werden umgehend mit der Umsetzung beginnen.',
        },
    },
    forms: {
        optional: 'Optional',
        addressDach: {
            street: {
                label: 'Straße',
                placeholder: '',
            },
            number: {
                label: 'Hausnummer',
                placeholder: '',
            },
            country: {
                label: 'Land',
            },
            zip: {
                label: 'PLZ',
                placeholder: '',
            },
            city: {
                label: 'Stadt',
                placeholder: '',
            },
            additional: {
                label: 'Zusatz',
                placeholder: '',
            },
        },
        addressNonDach: {
            street: {
                label: 'Straße & Hausnummer',
                placeholder: '',
            },
            country: {
                label: 'Land',
            },
            city: {
                label: 'PLZ & Stadt',
                placeholder: '',
            },
            additional: {
                label: 'Zusatz',
                placeholder: '',
            },
        },
        contact: {
            title: {
                label: 'Titel',
                placeholder: '',
            },
            first_name: {
                label: 'Vorname',
                placeholder: '',
            },
            last_name: {
                label: 'Nachname',
                placeholder: '',
            },
            gender: {
                label: 'Anrede',
                options: {
                    m: 'Herr',
                    f: 'Frau',
                    d: 'Neutral',
                },
            },
            email: {
                label: 'Mail',
                placeholder: '',
            },
            phone: {
                label: 'Telefon',
                placeholder: '',
            },
            mobile: {
                label: 'Mobile',
                placeholder: '',
            },
            is_authorized: {
                label: 'Weisungsberechtigt',
            },
            is_creating_account: {
                label: 'Einen @:resources.user.nameAlternative anlegen ?',
            },
        },
        updateUserPassword: {
            title: 'Passwort ändern',
            description: 'Aktualisieren Sie Ihr Passwort, das mit Ihrem Konto verknüpft ist.',
            password_current: {
                label: 'Aktuelles Passwort',
                placeholder: '',
            },
            password: {
                label: 'Neues Passwort',
                placeholder: '',
            },
            password_confirmation: {
                label: 'Passwort wiederholen',
                placeholder: '',
            },
        },
        twoFactorAuthentication: {
            title: 'Zwei-Faktor-Authentifizierung',
            description:
                'Erhöhen Sie die Sicherheit Ihres Kontos mit einer Zwei-Faktor-Authentifizierung.',
            has_two_factor_authentication: {
                label: 'Authenticator App',
            },
            recovery_codes: {
                label: '@:resources.user.attributes.recovery_codes',
            },
        },
        sendFeedback: {
            message: {
                label: 'Ihre Nachricht',
                placeholder: 'Was möchten Sie uns mitteilen?',
            },
            submit: 'Feedback senden',
        },
        ticket: {
            topic: {
                label: '@:resources.ticket.attributes.topic',
            },
            subject: {
                label: '@:resources.ticket.attributes.subject',
            },
            description: {
                label: '@:resources.ticket.attributes.description',
            },
            uploads: {
                label: 'Anhänge',
            },
        },
        ticketComment: {
            comment: {
                placeholder: '@:resources.ticketComment.name hinzufügen...',
            },
        },
    },
};
